<!-- 移动端注册 -->
<template>
  <div id="Register">
    <div class="close" @click="closeFun">
      <i class="iconfont icon-guanbi"></i>
    </div>
    <div class="logoCont">
      <img src="../../../assets/new_img/loginIn_new.png" alt="" class="logo" />
    </div>
    <div class="middle">
      <div class="login-dialog"></div>
      <van-form @submit="onSubmit">
        <van-field
          v-model="form.username"
          left-icon="smile-o"
          clearable
          name="username"
          :placeholder="$t('register.inputMsg')"
        >
          <template #left-icon>
            <i class="iconfont icon-wode-wode"></i>
          </template>
        </van-field>
        <van-field
          v-model="form.password"
          clearable
          left-icon="smile-o"
          :type="passType"
          name="password"
          :placeholder="$t('register.inputMsg')"
        >
          <template #left-icon>
            <i class="iconfont icon-mima1"></i>
          </template>
          <template #right-icon>
            <i
              class="iconfont"
              @click="showPassChange"
              :class="[!showPass ? 'icon-yincang' : 'icon-yanjing_xianshi']"
            ></i>
          </template>
        </van-field>
				
				<van-field
				  v-model="form.code"
				  left-icon="smile-o"
				  clearable
				  name="code"
				  :placeholder="$t('m_cue.yqm')">
				  <template #left-icon>
				    <i class="iconfont icon-wode-wode"></i>
				  </template>
				</van-field>
				
        <div>
          <van-button
            class="login-btn"
            round
            block
            type="info"
            native-type="submit"
            >{{ $t("register.btn") }}</van-button
          >
        </div>
      </van-form>
    </div>
    <div class="regist">
      <div class="link">
        <router-link replace to="/login">{{
          $t("m_register.dlxyzh")
        }}</router-link>
        <!-- <a href="/register" class=""> 快速注册 </a> -->
      </div>
    </div>
  </div>
</template>

<script>
import { register, loginDo } from "@/api/user";
import { mapActions } from "vuex";
import { Toast } from 'vant'
export default {
  name: "",
  data() {
    return {
      passType: "password",
      showPass: false,
      form: {
        username: "",
        password: "",
				
        code: "",
				
      },
    };
  },
  //生命周期 - 创建完成（访问当前this实例）
  created() {},
  //生命周期 - 挂载完成（访问DOM元素）
  mounted() {},
  methods: {
    ...mapActions(["SetToken"]),
    onSubmit(values) {
      console.log("submit", values);
      this.registerFun();
    },
    /* 密码显示隐藏 */
    showPassChange() {
      this.showPass = !this.showPass;
      if (this.showPass) {
        this.passType = "text";
      } else {
        this.passType = "password";
      }
    },
    /* 关闭 */
    closeFun() {
      this.$router.push({
        path: "/index",
      });
    },
    /* 注册 */
    registerFun() {
			if(this.form.code == ""){
				const toast = Toast.loading({
				  message: this.$t('m_cue.myqm'),
				  // forbidClick: true,
				  // loadingType: "spinner",
				  duration: 1000,
				});
			}else{
				// 自定义加载图标
				const toast = Toast.loading({
				  message: this.$t('common.loading'),
				  forbidClick: true,
				  loadingType: "spinner",
				  duration: 0,
				});
				register(this.form).then((res) => {
					console.log(res);
					Toast.clear();
					this.loginFun();
				});
			}
    },
		registerFun1() {
			// 自定义加载图标
			const toast = Toast.loading({
				message: this.$t('common.loading'),
				forbidClick: true,
				loadingType: "spinner",
				duration: 0,
			});
			register(this.form).then((res) => {
				console.log(res);
				Toast.clear();
				this.loginFun();
			});
    },
    /* 登录 */
    loginFun() {
      // 自定义加载图标
      const toast = Toast.loading({
        message: this.$t('common.loading'),
        forbidClick: true,
        loadingType: "spinner",
        duration: 0,
      });
      loginDo(this.form).then((res) => {
        console.log(res);
        this.SetToken({
          token: res.data.token,
        });
        Toast.clear();
        Toast.success(this.$t("m_login.dlcg"));
        this.$router.push("/index");
      });
    },
  },
};
</script>
<style scoped lang="less">
#Register {
  width: 100%;
  height: 100%;
  min-height: 100vh;
  position: relative;
  background: url(../../../assets/new_img/bg_login.png) no-repeat center;
  background-size: cover;
  display: flex;
  flex-direction: column;
  overflow-y: auto;

  .close {
    position: absolute;
    left: 0;
    top: 0;
    width: 0.32rem;
    height: 0.32rem;
    line-height: 0.32rem;
    margin: 0.28rem;
    z-index: 2;
    background-position: top center;
    background-size: 100%;

    .iconfont {
      color: #fff;
      font-size: 0.5rem;
    }
  }

  .logoCont {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1.5rem 0 1rem;
    position: relative;

    .logo {
      width: 2.7rem;
    }
  }

  .middle {
    .van-form {
      width: 6.4rem;
      margin: 0 auto;
      border-radius: 0.2rem;

      .van-cell {
        margin: 0 auto;
        width: 6.4rem;
        height: 1rem;
        background-color: #2b2e43;
        border-radius: 0.5rem;
        margin-bottom: 0.5rem;
      }

      .van-cell::after {
        border-bottom: none;
      }

      .forget-pwd {
        font-size: 0.24rem;
        text-align: right;
        margin-top: -0.3rem;
        padding-right: 0.1rem;

        a {
          color: #fff;
        }
      }

      .login-btn {
        width: 100%;
        height: 1rem;
        /* background-image: linear-gradient(0deg, #ceab77 0%, #eddbc1 100%); */
        background-image: linear-gradient(90deg, #2948ff, #396afc);
        border-radius: 0.5rem;
        font-size: 0.36rem;
        text-align: center;
        color: #323232;
        margin-top: 0.8rem;
        line-height: 1rem;
        letter-spacing: 0.05rem;
        border: none;

        .van-button__text {
          color: #ffffff;
        }
      }
    }
  }
}

#Register .regist {
  bottom: 0.4rem;
  left: 0;
  width: 100%;
  height: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.6rem 0;

  a {
    color: #ffffff;
    font-size: 0.28rem;
  }
}

.van-field__left-icon {
  .iconfont {
    font-size: 0.4rem;
    color: #9fb0ff;
  }
}

/deep/.van-field__right-icon {
  padding-top: 5px;

  .iconfont {
    font-size: 0.5rem;
    margin-top: 5px;
  }
}
</style>